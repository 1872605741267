<template>
  <div class="organization-real-name">
    <div
      class="to-authentication"
      @click="
        $router.push({
          path: '/copyrightService/realName/fillOrganizationInfo',
        })
      "
    >
      点击进行机构认证
    </div>
    <div class="description">
      <div class="part1">
        <div class="title">
          <img :src="require('assets/images/real-name.png')" alt="" />
          <span>实名认证</span>
        </div>
        <div class="info">
          <span>
            根据我国相关<span class="blue" @click="popLawsRegulations = true"
              >法律法规及规章</span
            >
            要求，需要进行实名认证。
          </span>
        </div>
      </div>
      <div class="part2">
        <div class="title">
          <img :src="require('assets/images/real-name-good.png')" alt="" />
          <span>实名认证好处</span>
        </div>
        <div class="info">
          <span>获得铁杆粉丝</span>
          <span
            >通过版权作品及知识服务持续获得现金、社交资源、行业领先地位等</span
          >
        </div>
      </div>
    </div>
    <!--法律法规-->
    <el-dialog
      :visible.sync="popLawsRegulations"
      :modal="false"
      :close-on-click-modal="false"
      title="法律法规及规章"
      class="laws-regulations"
    >
      <div class="content">
        <div class="laws-table">
          <el-table
            ref="multipleTable"
            :data="lawsRegulationsData"
            tooltip-effect="dark"
            height="280"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="40"> </el-table-column>
            <el-table-column prop="fileName" label="文件名称" width="400">
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="footer">
        <div class="footer-btns">
          <div class="footer-btn down" @click="download">下载</div>
          <div class="footer-btn cancel" @click="cancel">取消</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { checkUserRealName } from "api/copyrightService";
export default {
  name: "OrganizationRealName",
  data() {
    return {
      //显示法律法规弹窗
      popLawsRegulations: false,
      //法律法规文件
      lawsRegulationsData: [
        {
          fileName: "《中华人民共和国网络安全法》",
        },
        {
          fileName: "《中华人民共和国个人信息保护法》",
        },
        {
          fileName: "《中华人民共和国保守国家秘密法》",
        },
        {
          fileName: "《中华人民共和国数据安全法》",
        },
        {
          fileName: "《中华人民共和国电子签名法》",
        },
        {
          fileName: "《中华人民共和国电子商务法》",
        },
        {
          fileName: "《全国人民代表大会常务委员会关于加强网络信息保护的决定》",
        },
        {
          fileName: "《全国人民代表大会常务委员会关于维护互联网安全的决定》",
        },
      ],
    };
  },
  methods: {
    handleSelectionChange(sel) {},
    //下载法律法规
    download() {
      this.cancel();
    },
    //取消
    cancel() {
      this.popLawsRegulations = false;
    },
  },
};
</script>

<style lang="scss" scoped>
//法律法规弹窗
::v-deep .el-dialog__wrapper.laws-regulations .el-dialog {
  @include flex-center(column);
  justify-content: normal;
  align-items: normal;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0 !important;
  width: 489px;
  height: 405px;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-shadow: 0px 0px 7px 0px rgba(129, 135, 144, 0.2);
  border-radius: 5px;
  .el-dialog__header {
    padding: 15px 12px;
    .el-dialog__title {
      font-size: 14px;
      font-weight: 500;
      color: #000000;
    }
    .el-dialog__headerbtn {
      top: 12px;
      right: 11px;
    }
  }
  .el-dialog__body {
    padding: 0 11px 19px 23px;
    @include flex-center(column);
    justify-content: normal;
    align-items: normal;
    flex: 1;
    .content {
      flex: 1;
      .laws-table {
        ::-webkit-scrollbar {
          width: 2px;
          height: 79px;
        }
        ::-webkit-scrollbar-thumb {
          background-color: #ff6900;
        }
        .el-table::before {
          background-color: transparent;
        }
        .el-table .el-table__header-wrapper .el-table_1_column_2 {
          .cell {
            font-size: 14px;
            font-weight: 500;
            color: #999999;
          }
        }
        .el-table td.el-table__cell {
          &:first-child {
            @include noselect;
          }
          border-bottom: none;
          .cell {
            @include ellipsis;
          }
        }
        .el-table th.el-table__cell.is-leaf {
          border-bottom: none;
        }
      }
    }
    .footer {
      @include flex-between(flex-end);
      .footer-btns {
        @include flex-start;
        margin: 10px 9px 0 0;
        .footer-btn {
          @include noselect;
          width: 72px;
          height: 41px;
          border-radius: 3px;
          text-align: center;
          line-height: 41px;
          cursor: pointer;
          &.down {
            background: #4c8cff;
            border: 1px solid #ff6900;
            font-size: 16px;
            font-weight: 500;
            color: #ffffff;
          }
          &.cancel {
            margin-left: 12px;
            background: #ffffff;
            border: 1px solid #cecece;
            font-size: 16px;
            font-weight: 500;
            color: #999999;
          }
        }
      }
    }
  }
}
.organization-real-name {
  padding: 14px 40px 0;
  .to-authentication {
    @include noselect;
    width: 152px;
    height: 47px;
    background: #ff6900;
    border: 1px solid #ff6900;
    border-radius: 5px;
    text-align: center;
    line-height: 47px;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
  }
  .description {
    padding: 54px 0 0 20px;
    .part2 {
      margin-top: 40px;
      .info {
        @include flex-center(column);
        justify-content: normal;
        align-items: normal;
      }
    }
    .title {
      @include flex-start;
      img {
        width: 16px;
        height: 15px;
        margin-bottom: 1px;
      }
      span {
        margin-left: 5px;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
      }
    }
    .info {
      margin-top: 8px;
      margin-left: 20px;
      span {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        .blue {
          color: #ff6900;
          cursor: pointer;
        }
        &:first-child ~ span {
          margin-top: 8px;
        }
      }
    }
  }
}
</style>
